
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import { IReviewAPI } from '../../../interfaces/review'
import { useDialog } from 'naive-ui'
type Rating = 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5' | 'rating6'

export default defineComponent({
	components: {},
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			nickname: '',
			option1: '',
			option2: '',
			startDate: '',
			duration: '',
			rating1: 3,
			rating2: 3,
			rating3: 3,
			rating4: 3,
			rating5: 3,
			rating6: 3,
			rating7: 'yes',
			freeText: '',
			hospitalNameDetail: '',
		})
		const r7Handle = (e: any) => {
			modelRef.value.rating7 = e.target.value
		}
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			hospitalId: '',
			errorDialog,
			reviewId: '',
			hospitalName: '',
			formRef,
			model: modelRef,
			loading: false,
			success: false,
			err: '',
			r7Handle,
			rules: {
				option1: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							return value.length <= 500 || new Error('500文字以内で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				option2: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							return value.length <= 500 || new Error('500文字以内で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				startDate: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							return !!value.match(/(19|20)[0-9]{2}-[01][0-9]-[0123][0-9]/)
						},
						trigger: ['input', 'blur'],
					},
				],
				duration: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
				freeText: [
					{
						validator(rule: any, value: string) {
							return value.length <= 500 || new Error('500文字以内で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				hospitalNameDetail: [
					{
						validator(rule: any, value: string) {
							return value.length <= 500 || new Error('100文字以内で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: async function () {
		document.title = 'レビューを書く - Vets Index'
		const obj: any = location.search
			.slice(1)
			.split('&')
			.map((p) => p.split('='))
			.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
		const { type, hospitalId, reviewId } = obj
		try {
			this.hospitalId = hospitalId
			const data = (await api.get(`/v1/hospital_data/${obj.hospitalId}`, true)) as IReviewAPI
			this.hospitalName = data.hospitalData.name
		} catch (e: any) {
			this.loading = false
			this.err = e.message || e.toString()
			console.log(this.err)
			console.error(e)
		}
	},
	methods: {
		post: async function () {
			const param: any = {
				nickname: this.model.nickname,
				hospital: this.hospitalId,
				hospitalName: this.model.hospitalNameDetail,
				option1: this.model.option1,
				option2: this.model.option2,
				startDate: this.model.startDate,
				duration: this.model.duration,
				rating1: this.model.rating1,
				rating2: this.model.rating2,
				rating3: this.model.rating3,
				rating4: this.model.rating4,
				rating5: this.model.rating5,
				rating6: this.model.rating6,
				rating7: this.model.rating7,
				freeText: this.model.freeText,
				status: 'reviewWaiting',
			}
			try {
				this.success = false
				this.loading = true
				const data = await api.post('/v1/student/review/post', param)
				if (data.success) {
					this.loading = false
					this.success = true
					this.$router.push(`/h/${this.hospitalId}`)
				} else {
					this.loading = false
					console.error(data)
					if (data.message) this.err = data.message
					if (data.message) this.errorDialog(data, () => true)
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		notReviewed: function (target: Rating) {
			this.model[target] = -1
		},
	},
})
